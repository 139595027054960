import { defineStore } from 'pinia';
import type { JOB_CHANGES, JOB_TYPES } from '~/const/job';
import type { SALARY_RANGES } from '~/const/salary';
import type { dateString } from '~/utils/const';

type TargetType = targetPattern;
type EntryType =
  | ''
  | 'registration'
  | 'lp001'
  | 'lp002'
  | 'lp003'
  | 'lp004'
  | 'lp005'
  | 'lp006'
  | 'lp007'
  | 'lp008'
  | 'jobPositions';
type SlidePattern = 'prev' | 'next';
type UtmParameter = {
  utmCampaign?: string;
  utmSource?: string;
  utmMedium?: string;
  utmContent?: string;
};

const INIT_TARGET_TYPE = 'none' as const;
const INIT_ENTRY_TYPE = '' as const;
const INIT_SLIDE_PATTERN = 'prev' as const;
const INIT_CURRENT_STEP = 1 as const;
const INIT_FORM_ERROR = {
  phoneNumber: {
    isOptional: true,
    isError: false,
    errorText: '',
  },
  name: {
    isError: false,
    errorText: '',
  },
  nameKana: {
    isError: false,
    errorText: '',
  },
  email: {
    isError: false,
    errorText: '',
  },
  latestCompanyName: {
    isError: false,
    errorText: '',
  },
  password: {
    isError: false,
    errorText: '',
  },
} as const;
const INIT_CLIENT_PROFILR = {
  email: '',
  name: '',
  nameKana: '',
  password: '',
  birthDate: '0000-01-01',
  birthYear: 0,
  birthMonth: 0,
  birthDay: 0,
  phoneNumber: '',
  currentSalary: '',
  currentJobPosition: '',
  demandJob: '',
  latestCompanyName: '',
  desiredLocations: [] as string[],
} as const;
const INIT_UTM_PARAMETER = {
  utmCampaign: undefined,
  utmSource: undefined,
  utmMedium: undefined,
  utmContent: undefined,
} as const;
const INIT_INTERVIEW_URL = '' as const;

type State = {
  entryType: EntryType;
  targetType: TargetType;
  currentStep: number;
  formError: {
    phoneNumber: {
      isError: boolean;
      errorText: string;
    };
    name: {
      isError: boolean;
      errorText: string;
    };
    nameKana: {
      isError: boolean;
      errorText: string;
    };
    email: {
      isError: boolean;
      errorText: string;
    };
    latestCompanyName: {
      isError: boolean;
      errorText: string;
    };
    password: {
      isError: boolean;
      errorText: string;
    };
  };
  clientProfile: {
    email: string;
    name: string;
    nameKana: string;
    birthDate: dateString;
    birthYear: number;
    birthMonth: number;
    birthDay: number;
    phoneNumber: string;
    currentSalary: (typeof SALARY_RANGES)[number]['value'] | '';
    currentJobPosition: (typeof JOB_TYPES)[number]['value'] | '';
    latestCompanyName: string;
    demandJob: (typeof JOB_CHANGES)[number]['value'] | '';
    desiredLocations: string[];
  };
  slidePattern: SlidePattern;
  utmParameter: UtmParameter;
  interviewUrl: string;
};

export const useRegisterFormStore = defineStore('registerForm', {
  state: (): State => ({
    targetType: INIT_TARGET_TYPE,
    entryType: INIT_ENTRY_TYPE,
    currentStep: INIT_CURRENT_STEP,
    formError: INIT_FORM_ERROR,
    clientProfile: INIT_CLIENT_PROFILR,
    slidePattern: INIT_SLIDE_PATTERN,
    utmParameter: INIT_UTM_PARAMETER,
    interviewUrl: INIT_INTERVIEW_URL,
  }),
  actions: {
    setSlidePattern(value: SlidePattern) {
      this.slidePattern = value;
    },
    setCurrentStep(value: number) {
      this.currentStep = value;
    },
    setEntryType(value: EntryType) {
      this.entryType = value;
    },
    setDemandJobChange(value: (typeof JOB_CHANGES)[number]['value'] | '') {
      this.clientProfile.demandJob = value;
    },
    setBirthYear(value: number) {
      this.clientProfile.birthYear = value;
    },
    setBirthMonth(value: number) {
      this.clientProfile.birthMonth = value;
    },
    setBirthDay(value: number) {
      this.clientProfile.birthDay = value;
    },
    setBirthDate(value: dateString) {
      this.clientProfile.birthDate = value;
    },
    setCurrentJobPosition(value: (typeof JOB_TYPES)[number]['value'] | '') {
      this.clientProfile.currentJobPosition = value;
    },
    setCurrentSalaryId(value: (typeof SALARY_RANGES)[number]['value'] | '') {
      this.clientProfile.currentSalary = value;
    },
    setLatestCompanyName(value: string) {
      this.clientProfile.latestCompanyName = value;
    },
    setDesiredLocations(value: string[]) {
      this.clientProfile.desiredLocations = value;
    },
    setName(value: string) {
      this.clientProfile.name = value;
    },
    setNameKana(value: string) {
      this.clientProfile.nameKana = value;
    },
    setEmail(value: string) {
      this.clientProfile.email = value;
    },
    setPhoneNumber(value: string) {
      this.clientProfile.phoneNumber = value;
    },
    setFormError(value: {
      id: keyof typeof INIT_FORM_ERROR;
      isError: boolean;
      errorText: string;
      isOptional?: boolean;
    }) {
      this.formError[value.id] = {
        isError: value.isError,
        errorText: value.errorText,
      };
    },
    setTargetType(value: TargetType) {
      this.targetType = value;
    },
    setUtmParameter(key: keyof UtmParameter, value: string) {
      this.utmParameter[key] = value;
    },
    setInterviewUrl(value: string) {
      this.interviewUrl = value;
    },
  },
  persist: {
    storage: persistedState.localStorage,
    key: 'registerForm',
  },
});
